import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class BookingService {
  getBookings(userId, start, end) {
    return axios
      .get(
        `${config["apiUrl"]}booking/user/${userId}/start/${start}/end/${end}`,
        {
          headers: authHeader()
        }
      )
      .then(response => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      }).catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  createBooking(bodyParms) {
    return axios
      .post(`${config["apiUrl"]}booking`, bodyParms, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null
      });
  }

  updatedBooking(bookingId, bodyParms) {
    return axios
      .patch(`${config["apiUrl"]}booking/id/${bookingId}`, bodyParms, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null
      });
  }

  removeBooking(bookingId) {
    return axios
      .delete(`${config["apiUrl"]}booking/id/${bookingId}`, {
        headers: authHeader()
      })
      .then(response => {
        if (response.status != undefined && response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch(error => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null
      });
  }
}

export default new BookingService();
